.fts-rejected-read-reason-modal {
  .modal-content {
    padding: 1rem;
  }
}
.fts-rejected-reason-list-modal-title {
  display: flex;
  justify-content: space-between;

  .title-row {
    svg {
      stroke: none;
    }
  }
}
.fts-rejected-modal-message {
  padding: 1rem;
  margin-left: 5rem;
  padding-top: 0;
}
