.proofs-nav {
  border-radius: 0 !important;
  padding: 0 24px !important;
  border-bottom: 1px solid #e5eaef;

  .nav-item {
    margin-right: 32px;
    cursor: pointer;
    .nav-link {
      color: #9d9d9d;
      padding-bottom: 10px;
    }

    .nav-link.active {
      color: #00b5f4;
      border-bottom: 2px solid #00b5f4;
    }
  }
}

.fts-sent-proofs-table-title {
  display: flex;
  align-items: center;
  color: #121a22;
  font-size: 20px;
  line-height: 24px;
}
